/* General Color and Font Theme */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background-color: #D2E9E9;
    margin: 0;
}
h1, h2, h3, h4, h5, h6, ul, ol, li, p, .publications a { 
    color: #0A4D68;
    font-family: "Poppins", sans-serif;
}
.about h1, .education h1, .experience h1, .projects h1, .publications h1 {
    color: #0A4D68;
    font-weight: bold;
}
.jumbotron {
    height: 90vh;
    background-color: transparent !important;
    padding: none;
    border: none;
    margin-left: 3%;
    margin-right: 3%;
}

/* Navbar */
.nav-bar {
    background-color: #0A4D68;
}
.nav-link {
    color: #D2E9E9 !important; 
    font-style: bold;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    padding: 8px;
    text-decoration: none;
}
.nav-link:hover {
    /* border-bottom: 2px solid #0A4D68; */
    color: #088395;
    text-decoration: none;
}


/* Landing Page Button */
.container {
    height: 70vh;
    width: 100%;
}
.btn {
    background-color: transparent !important;
    border: 1.5px solid #0A4D68 !important;
    border-radius: 50px !important;
    width: 60%;
}
@media only screen and (min-width: 700px){
    .btn {
        width: 20%;
    }
}
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}
.button-link {
    color: #0A4D68;
}

.btn .button-link:hover {
    border: none;
    color: #088395 !important;
    text-decoration: none;
}

/* Landing Page */
.landing {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: left;
}

/* Hyper Links */
.about span, .about a, .education a, .experience span, .experience a, .education span {
    color: #088395;
}
.about a:hover, .about a:active, .education a:hover, .education a:active, .experience a:active, .experience a:hover  {
    color: #0A4D68;
    font-style: bold;
    text-decoration: none;
}
.about a i, .education a i {
    font-size: 100px;
    display: inline-block;
    margin: 2%;
    color: #0A4D68;
    text-decoration: none;
}
@media only screen and (max-width: 700px){
    .about a i, .education a i {
        font-size: 60px;
    }
}
.about a i:hover, .about a i:active, .education a i:hover, .education a i:hover, .publications a:hover, .publications a:active {
    color: #088395;
    text-decoration: none;
}

/* Education Page */
.education .col {
    justify-content: center;
    text-align: center;
}

/* Publications Ordered List */
*, ::after, ::before {
    box-sizing: border-box;
}

ol {
    counter-reset: list;
}

ol > li {
    list-style: none;
    position: relative;
}

ol > li:before {
    content: "[" counter(list) "]";
    counter-increment: list;
    left: -40px;
    padding-right: 10px;
    position: absolute;
    text-align: right;
    width: 40px;
}